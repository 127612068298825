import styled from "styled-components";

export const StyledNav = styled.nav`
box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);

  h6 {
    @media (max-width : 360px){
      font-size: 15px;
    }
  }

  .bg-light-home {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-rgb),
      var(--bs-bg-opacity)
    ) !important;
box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
padding: 0px 40px;

@media (max-width : 360px){
  padding: 0px 0px;

}

    }

    .nav-width{
      --bs-bg-opacity: 1;
      background-color: rgba(
        var(--bs-light-rgb),
        var(--bs-bg-opacity)
      ) !important;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  padding: 10px 40px;

  @media (max-width : 360px){
    padding: 10px 0px;
  
  }
      }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-light-rgb),
    var(--bs-bg-opacity)
  ) !important;
box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
padding: 0px 170px;

@media (max-width : 1740px){
  padding: 0px 100px;

}


@media (max-width : 1250px){
  padding: 0px 80px;

}

@media (max-width : 1199px){
  padding: 0px 70px;

}

@media (max-width : 1050px){
  padding: 0px 35px;

}

@media (max-width : 360px){
  padding: 0px 0px;

}
  }




  .nav-toggler-align {
    margin-left: 24rem;
    margin-top: 10px;
    //  margin-left: 383rem;
    // margin-top: 12px;

    @media (max-width: 876px) {
      margin-left: 22rem;
    }

    @media (max-width: 840px) {
      margin-left: 29rem;
    }

    @media (max-width: 829px) {
      margin-left: 20rem;
    }

    @media (max-width: 790px) {
      margin-left: 15rem;
    }

    @media (max-width: 729px) {
      margin-left: 15rem;

    }

    @media (max-width: 709px) {
      margin-left: 15rem;
    }

    @media (max-width: 677px) {
      margin-left: 10rem;
    }

    @media (max-width: 645px) {
      margin-left: 10rem;
    }

    @media (max-width: 614px) {
      margin-left: 10rem;
    }

    @media (max-width: 567px) {
      margin-left: 0rem;
    }

    @media (max-width: 450px) {
      margin-left: 1rem;
    }

    @media (max-width: 400px) {
      margin-left: -3rem;
      
    }

  }

  
  & li {
    list-style: none;
  }
  .navbar-header {
    float: left;
    padding: 6px;
    text-align: center;


    @media (max-width: 1200px) {
      padding: 0px;

    }
  }
  .navbar-nav > li {
    margin-left: 10px;
    margin-right: 15px;

    @media (man-width: 1200px) {
      margin-left: 1px;
      margin-right: 10px;
    }
  }
  .navbar-nav .nav-item a {
    // display: block;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 14px;
    color: #000 !important;
}

.navbar-nav .nav-item a {
// line-height: 50px;
    position: relative;
}
.navbar-nav .nav-item.active >  a:before ,.navbar-nav .nav-item.active >  a:hover:before{
  width: 100%;

} 

.show{
  display: flex;
}

.hide{
  display:none;
  margin-top: 15px;

}
.navbar-nav .nav-item a:before {
  content: "";
  width: 0%;
  height: 3px;
  background-color: #7b0b14;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
}

  .navbar-brand {
    float: right;
  }
  .show-nav {
    display: block;
    margin: 0 auto;
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999999;
    background-color: #fff;

    & ul{
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;

      & li{
   margin: 0;
  position: relative;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid rgba(255,255,255,0.05);
  padding-top: 0;
  padding-bottom: 0;


  & a {
    color: #b1b1b1 !important;
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 50px;
    text-decoration: none;

  }
      }
    }
  }
 
  & img {
    max-width: 90%;
    max-height: 60px;
    // max-width: 75px;
    // max-height: 60px;
    // width: 50px;
    // height: 50px;
    // border-radius: 50%;
    // object-fit: cover;
    @media (max-width: 400px) {
    
        max-width: 22%;
        max-height: 60px;
      
    }

  }
  .navbar-brand {
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: #000000;
    line-height: 1.3;
    font-weight: bold;
    font-size: 19px;  
    margin-left:20px;

  @media (max-width: 1200px) {
    margin-left: 1rem;
}

  & span {
    margin: 10px;
    @media (max-width: 767px) {
      margin: 1px;

  }

  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    min-width: max-content !important;

}

  .nav-link{
    display: block;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 13px;
    color: #000 !important;
}



.navbar-nav {
   --bs-nav-link-color:black; 
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
}


#main-nav & ul & li.current-menu-item & a&:before, #main-nav & ul & li & a&:hover&:before {
  width: 100%;
}

#main-nav > ul > li > a&:before {
  content: "";
  width: 0%;
  height: 3px;
  background-color: #00abc9;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
}

// .navbar-toggler {
//   padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
//   font-size: var(--bs-navbar-toggler-font-size);
//   line-height: 4;
//   color: var(--bs-navbar-color);
//   background-color: transparent;
//   border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
//   border-radius: var(--bs-navbar-toggler-border-radius);
//   transition: var(--bs-navbar-toggler-transition);
  
// }
#navbar-toggle{
  float: right;
  position: absolute;
  top: 10%;
  right: 0;
  margin-right: 47px;
  transform: translate3d(0, -50%, 0);
  background: transparent;
  cursor: pointer;
  transition: all ease 0.3s;
}



.main-nav-mobi {
  display: block;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 999999;
  background-color: #222;


}

.main-nav-mobi ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-nav-mobi ul li {
  margin: 0;
  position: relative;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid rgba(255,255,255,0.05);
  padding-top: 0;
  padding-bottom: 0;
}

.main-nav-mobi ul > li > a {
  color: #b1b1b1;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 50px;
  text-decoration: none;
}

.navbar-toggler{



  margin-top: 10px !important;
  margin-left: 260px !important;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  margin-top: 15px !important;
}
 
`;
