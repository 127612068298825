import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../features/user/authSlice";
import orderSlice from "../features/order/orderSlice";
import remarkSlice from "../features/remark/remarkSlice";
import gateWaySlice from "../features/paymentgateway/gateWaySlice";
import manageTeamSlice from "../features/manageteam/manageTeamSlice";
import visitorSlice from "../features/visitor/visitorSlice";

const store = configureStore({
    reducer: {
        user: authSlice,
        order: orderSlice,
        remark: remarkSlice,
        gateway: gateWaySlice,
        manageTeam: manageTeamSlice,
        visitor: visitorSlice
    }   
});

export default store;

