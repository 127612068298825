import styled from "styled-components";

export const StyledOrderBlock = styled.div`
  padding: 10px;
  margin: 5px;

  @media only screen and (max-width: 767px) {
    padding: 0 15px 15px;
  }
  & p {
    // color: red;
    text-align: right;
    font-size: 16px;
  }

  & img {
    // display: block;
    margin-left: auto;
    margin-right: auto;
  }
  textarea.form-control {
    min-height: 88px;
    height: auto;
    margin-bottom: 15px;
  }

  .input-class {
    margin-top: 15px;
  }
  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .checkbox {
    & input {
      webkit-appearance: none;
      appearance: none;
    }

    & span {
      width: 25px;
      height: 25px;
      border: 1px solid #848484;
      border-radius: 5px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      position: relative;
      top: -1px;

      &:before {
        content: "";
        display: block;
        width: 7px;
        height: 14px;
        border-bottom: 2px solid #ce1127;
        border-right: 2px solid #ce1127;
        transform: rotate(45deg) translate(-50%, -50%);
        position: absolute;
        left: 30%;
        top: 50%;
        opacity: 0;
        transform: translate;
      }
    }
  }

  .checkbox input:checked + span:before {
    opacity: 1;
  }
  .checkbox label {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  & p {
    color: red;
    font-weight: 500;
  }
  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .form-inline {
    display: flex;
    gap: 10px;
    margin-to: 15px;

    @media only screen and (max-width: 767px) {
      display: block;
    }
  }

  .form-control {
    border: 1px solid #e1e1e1;
    box-shadow: none;
    font-size: 16px;
    color: #111;
    height: 40px;
    background: #fff;
  }

  @media only screen and (max-width: 767px) {
    .fw-mobileview table td {
      display: block;
      min-width: 100%;
      width: 100%;
    }
  }
  .download-icons {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .font-normal {
    font-weight: 400 !important;
  }

  .download-blue-btn {
    background-color: #ce1127;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 2px;
    display: inline-block;
    border: none;
    margin: 3px;
    border-radius: 4px;
  }
  .blue-btn {
    background-color: #ce1127;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: inline-block;
    border: none;
    margin: 3px;
    border-radius: 4px;

    & img {
      margin-left: 10px;
      vertical-align: middle;
      display: inline-block;
    }
    &:focus,
    &:hover {
      background-color: #0b3157;
      color: #fff;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    @media only screen and (max-width: 991px) {
      font-size: 15px;
      padding: 12px 10px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 15px;
      padding: 10px 15px;
    }
  }
  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;

    @media only screen and (max-width: 767px) {
      font-size: 10px;
      padding: 6px 1px;
      white-space: pre-line;
    }
  }

  .blue-btn {
    white-space: nowrap;
  }
  .blue-btn {
    margin-top: 5px;
  }

  & a {
    color: #337ab7;
    text-decoration: none;
  }
  & h3 {
    background: #0b3157;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 15px 25px;
    margin: 0px -25px 25px;

    @media only screen and (max-width: 767px) {
      font-size: 18px;
      padding: 15px 15px;
      margin: 0px -15px 15px;
    }
  }
  .table-block table {
    width: 100%;
    table-layout: fixed;
  }
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }

  .table-responsive {
    min-height: 0.01%;
    overflow-x: auto;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 15px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd;
    }
  }

  .table-block table {
    width: 100%;
    table-layout: fixed;

    @media only screen and (max-width: 1350px) {
      table-layout: auto;
    }
  }
  .table-block table {
    width: 100%;
  }

  .table-block.table-responsive {
    border: 0px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 15px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd;
    }
  }
  .table-block table tr:first-child td {
    border-top: 0;
  }
  & table td:nth-child(2) {
    font-weight: 600;
  }
  .file-upload {
    border: 1px solid #e1e1e1;
    box-shadow: none;
    font-size: 18px;
    color: #111;
    height: 40px;
    background: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  .file-upload span {
    background: #ce1127;
    color: #fff;
    border-radius: 2px;
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
  }

  .form-control {
    border: 1px solid #e1e1e1;
    box-shadow: none;
    font-size: 16px;
    color: #111;
    height: 40px;
    background: #fff;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .table-block table th {
    font-weight: 600;
    font-size: 18px;
    padding: 10px;

    @media only screen and (max-width: 767px) {
      font-weight: 600;
      font-size: 15px;
      padding: 5px;
    }
  }

  .table-block.table-responsive {
    @media only screen and (max-width: 767px) {
      border: 0px;
    }
  }

  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;

    @media only screen and (max-width: 991px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      padding: 5px;
    }
  }
  .table-block table tr:nth-child(even) td {
    background: #f9fcff;
  }

  @media only screen and (max-width: 1350px) {
    .order-dtl-block .blue-border {
      white-space: nowrap;
    }
    .order-dtl-block .table-block table {
      table-layout: auto;
    }
  }

  @media only screen and (max-width: 767px) {
    .table-block.table-responsive {
      border: 0px;
    }
  }
`;

export const DefenceContainer = styled.div`
  padding: 10px;
  margin: 5px;

  & p {
    // color: red;
    text-align: right;
    font-size: 16px;
    line-height: 1.61em;
  }

  & img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  & li {
    list-style: none;
  }
  .header {
    float: right;
  }

  & h2 {
    text-align: center;
    text-decoration: none;
  }

  & strong {
    font-weight: 700;
  }

  .main {
    margin-top: 16rem;
  }
  & a {
    text-decoration: none;
    color: #f96332;
  }

  & span {
    text-decoration: underline;
    font-weight: bold;
  }

  & button {
    color: #fff;
    background: #0d6efd;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    float: right;
  }

  .flex-class {
    display: flex;
  }
  .list-content {
    padding-left: 20px;
  }
  & img {
    vertical-align: middle;
    width: 45rem;
  }

  & span img {
    width: 20px;
  }

  .container-box {
    border: 2px solid black;
  }

  .arrival-img {
    width: 20rem;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }

  .download-history {
    border: 3px solid black;
    padding: 5px;
    overflow-x: scroll;

    & tr {
      border-bottom: 1px solid #fff;
    }
  }

  .email {
    text-align: right;
    text-decoration: underline;
  }

  & span {
    text-decoration: underline;
  }

  & table {
    & th {
      font-size: 14px;
    }
  }
`;

export const StyledImage = styled.div`
  position: absolute;
  padding-bottom: 10px;
  background-color: #fff;
  padding-top: 10px;
  padding-left: 10px;

  .admin-logo {
    width: 100px !important;
  }
  & .logo-section {
    display: flex;
  }

  & .logo-section .logo-content {
    padding-left: 4px;
    padding-top: 12px;
    color: #da1d25;
    font-weight: bold;
    font-size: 22px;
    text-align:start;
  }
  & .logo-section .logo-text {
    padding-left: 4px;
    color: #2a2d72;
    font-weight: bold;
    white-space: nowrap;
    font-size: 22px;
    line-height: 1px;
    text-align:start;
  }
`;

export const StyledDetail = styled.div`
  font-size: 16px;
  line-height: 1.61em;
`;

export const StyledBlock = styled.div`
  border: black;
  border-width: 3px;
  border-style: solid;
  font-weight: bold;
  text-align: left;
`;
