import React from "react";
import { NavLink } from "react-router-dom";
import { Card, CardDiv, StyledBody, StyledCard } from "./style";
import PendingOrder from "../../../assets/images/pending_order.svg";
import CompletedOrder from "../../../assets/images/completed_order.svg";
import ContactCustomer from "../../../assets/images/contact_customer.svg";
import NewOrder from "../../../assets/images/new_order.svg";
import { useSelector } from "react-redux";

const AdminHeader = () => {
  const count = useSelector((state) => state?.order?.count);
  return (
    <StyledBody>
      <StyledCard>
        <Card>
          <CardDiv>
            <NavLink to="/admin/recent-order">
              <div data-target="neworder">
                <p className="m-b-20">
                  <img src={NewOrder} alt="New Order" />
                </p>
                <span>New Orders</span>
                <h3>{count?.newOrdersCount}</h3>
              </div>
            </NavLink>
          </CardDiv>
        </Card>

        <Card>
          <CardDiv>
            <NavLink to="/admin/priority-order">
              <div data-target="priorityorder">
                <p className="m-b-20">
                  <img src={NewOrder} alt="Priority Order" />
                </p>
                <span>Priority Order</span>
                <h3>{count?.priorityOrdersCount}</h3>
              </div>
            </NavLink>
          </CardDiv>
        </Card>

        <Card>
          <CardDiv>
            <NavLink to="/admin/pending-order">
              <div data-target="pendingorder">
                <p>
                  <img src={PendingOrder} alt="Pending Order" />
                </p>
                <span>Pending Orders</span>
                <h3>{count?.pendingOrdersCount}</h3>
              </div>
            </NavLink>
          </CardDiv>
        </Card>

        <Card>
          <CardDiv>
            <NavLink to="/admin/completed-order">
              <div data-target="completedorder">
                <p>
                  <img src={CompletedOrder} alt="Completed Order" />
                </p>
                <span>Completed Orders</span>
                <h3>{count?.completedOrdersCount}</h3>
              </div>
            </NavLink>
          </CardDiv>
        </Card>

        <Card>
          <CardDiv>
            <NavLink to="/admin/contact-customer">
              <div data-target="contactcustomerorder">
                <p>
                  <img src={ContactCustomer} alt="Contact Customer" />
                </p>
                <span>Contact Customer</span>
                <h3>{count?.contactCustomerOrdersCount}</h3>
              </div>
            </NavLink>
          </CardDiv>
        </Card>
      </StyledCard>
    </StyledBody>
  );
};

export default AdminHeader;
