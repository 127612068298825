import styled from "styled-components";
import backgroundImg from "../../assets/images/loginBg.jpg";

export const StyledLogin = styled.div`
  min-height: 100vh;
  padding: 250px 30px 100px;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  @media only screen and (max-width: 767px) {
    padding: 150px 30px 50px;
  }

  & p {
    color: red;
  }
`;

export const LoginContainer = styled.div`
  margin: 0 auto;
  max-width: 810px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  background: #fff;
  border-radius: 5px;
  padding: 50px;
  position: relative;
  @media only screen and (max-width: 767px) {
    padding: 20px;
  }
`;

export const LoginLogo = styled.div`
  background: #fff;
  padding: 70px 30px 0px 30px;
  width: 350px;
  text-align: center;
  border-radius: 50%;
  height: 350px;
  margin: -210px auto -140px;

  img ,
  svg {
    vertical-align: middle;
    max-width: 100%;
    height: 90px;
    width: 180px;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  @media only screen and (max-width: 767px) {
    padding: 40px 30px 0px 30px;
    width: 200px;
    height: 200px;
    margin: -115px auto -80px;
  }
`;

export const FormContainer = styled.div`
  position: relative;

  .form-control {
    font-size: 18px;
    color: #000c19;
    border: 0px;
    border: 1px solid #0b3157;
    box-shadow: none;
    border-radius: 5px;
    height: 60px;

    @media only screen and (max-width: 767px) {
      font-size: 16px;
      height: 45px;
    }
  }

  .form-control &:placeholder {
    color: #191919;
    opacity: 1;
  }
  & label {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;

    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .form-group {
    margin-bottom: 30px;
  }

  & checkbox label {
    font-size: 18px;
    padding: 0px;
    margin: 0;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .checkbox {
    & input {
      webkit-appearance: none;
      appearance: none;
    }

    & span {
      width: 25px;
      height: 25px;
      border: 1px solid #848484;
      border-radius: 5px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      position: relative;
      top: -1px;

      &:before {
        content: "";
        display: block;
        width: 7px;
        height: 14px;
        border-bottom: 2px solid #ce1127;
        border-right: 2px solid #ce1127;
        transform: rotate(45deg) translate(-50%, -50%);
        position: absolute;
        left: 30%;
        top: 50%;
        opacity: 0;
        transform: translate;
      }
    }
  }

  .checkbox input:checked + span:before {
    opacity: 1;
  }
  .form-btn {
    text-align: center;
  }
  .forget-link {
    color: #ce1127;
    font-size: 18px;
    text-decoration: underline;
    display: inline-block;
    margin-top: 30px;
  }
  .blue-btn {
    background-color: #ce1127;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: inline-block;

    @media only screen and (max-width: 767px) {
      font-size: 15px;
      padding: 10px 15px;
    }
  }
  .blue-btn &img {
    margin-left: 10px;
    vertical-align: middle;
    display: inline-block;
  }
  .blue-btn:hover,
  .blue-btn:focus {
    background-color: #0b3157;
    color: #fff;
    text-decoration: none;
  }

  .forget-link {
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      margin-top: 15px;
    }
  }
`;